<template>
    <div class="two-factor-pin-code">
        <div v-if="isFirstLogin" class="two-factor-pin-code__text" name="pinCodeText">{{ consts.pinCodeTextFirstLogin }}</div>
        <div v-else class="two-factor-pin-code__text" name="pinCodeText">{{ consts.pinCodeText }}</div>
        <div class="two-factor-pin-code__input-container">
            <input
                :value="pinCode"
                name="pin-code"
                type="text"
                class="tip-custom-input two-factor-pin-code__input"
                :class="isInvalidClass"
                maxlength="6"
                @input="pinCodeInput"
            />
            <div class="two-factor-pin-code__error">
                <span v-if="validationResult && !isVerifying">{{ validationResult.message }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import twoFactorAuthenticationConsts from '@/consts/twoFactorAuthenticationConsts';
export default {
    data() {
        return {
            consts: twoFactorAuthenticationConsts
        };
    },
    computed: {
        ...mapState('twoFactor', ['pinCode', 'canVerify', 'validationResult', 'isVerifying']),
        ...mapGetters('twoFactor', ['isFirstLogin', 'isPinCodeValid']),
        isInvalidClass() {
            if ((!this.canVerify || !this.isPinCodeValid) && this.pinCode && !this.isVerifying) {
                return 'tip-custom-input--invalid';
            }
            return '';
        }
    },
    methods: {
        ...mapActions('twoFactor', ['updatePinCode']),
        pinCodeInput(event) {
            this.updatePinCode(event.target.value);
        }
    }
};
</script>
<style lang="scss">
.two-factor-pin-code {
    &__text {
        margin: auto 2.5rem 1rem 2.5rem;
        text-align: center;
    }
    &__input-container {
        margin: auto;
        width: $input-width;
    }
    &__error {
        color: $outrageous-orange;
        font-size: 0.9rem;
        margin: 0;
        min-height: 1rem;
        text-align: left;
        width: 22rem;
    }
}
</style>
